import React, { Fragment, useState } from "react";

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { mergeStyleSets, PrimaryButton } from "@fluentui/react";


interface ReactSelectorProps {
    id: string;
    value: any;
    defaultValue?: any;
    multiline?: boolean;
    onChange: (styleElementName: string, value: any) => void;
}

const classNames = mergeStyleSets({
    textarea: {
        outline: 'none',
        ':hover': {
            border: `2px solid red`,

        }
    },
    addDefaultTemplateButton: {
        display: 'flex',
        marginTop: 5,
        marginLeft: 'auto'
        // float: 'right'
    }
});
export const ReactSelector = (props: ReactSelectorProps) => {
    const { id, value, onChange, defaultValue } = props;

    const [reactValue, setReactValue] = useState(value);

    return (
        <Fragment>
            {/* <TextField multiline={props.multiline} defaultValue={value}  /> */}

            <Editor
                value={reactValue}
                defaultValue={reactValue}
                onValueChange={code => setReactValue(code)}
                onBlur={(e: any, newValue?: string) => { onChange(id, e.target.value) }}
                highlight={code => code && highlight(code, languages.js, "jsx")}
                padding={10}
                style={{
                    // fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    borderRadius: 4,
                    border: `2px solid #DDE2EA`,
                    minHeight: 80,
                    maxHeight: 300,
                    overflow: 'auto',
                    outline: 'none'
                }}
                textareaClassName={classNames.textarea}
            />

            {defaultValue && <PrimaryButton className={classNames.addDefaultTemplateButton} text="Add default template" onClick={() => { onChange(id, defaultValue); setReactValue(defaultValue) }} />}

        </Fragment>
    );
}