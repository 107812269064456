import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { highlight, languages } from 'prismjs';
import { Icon, mergeStyles, mergeStyleSets, PrimaryButton, TextField } from "@fluentui/react";
import { JsonEditor } from 'jsoneditor-react';
import enUSJson from 'botframework-webchat-api/src/localization/en-US.json';

declare const DruidWebchat_v2: any;
interface ReactSelectorProps {
    id: string;
    value: any;
    defaultValue?: any;
    multiline?: boolean;
    languages: any[];
    onChange: (styleElementName: string, value: any) => void;
}

const classNames = mergeStyleSets({
    textarea: { outline: 'none' },
    addDefaultTemplateButton: {
        display: 'flex',
        marginTop: 5,
        marginLeft: 'auto'
        // float: 'right'
    },
    container: {
        '> div': {
            height: 380
        },
        '.jsoneditor': {
            border: '1px solid #DDE2EA !important',
            '.jsoneditor-menu': {
                backgroundColor: '#70B244',
                borderBottom: '1px solid #DDE2EA !important',
                '.jsoneditor-sort, .jsoneditor-transform, .jsoneditor-repair': {
                    display: 'none'
                }
            }
        }
        
    },
    textField: {
        textarea: {
            resize: 'none'
        },
        paddingBottom: 10
    }
});

const subCategoryHeaderContainer = mergeStyles(
    {
        display: "flex",
        alignItems: "center",
        margin: '10px 0'
    }
);

const subCategoryHeaderIcon = mergeStyles(
    {
        fontSize: '0.75rem',
        display: 'flex',
        aligntItems: 'center',
        cursor: 'pointer'
    }
);

const subCategoryHeader = mergeStyles(
    {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        color: '#212529',
        fontWeight: 'bold',
        cursor: 'pointer',
    }
);
export const LocalizationSelector = (props: ReactSelectorProps) => {
    const { id, value, onChange, defaultValue, languages } = props;

    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(true); // collapses subCategory headers
    }, []);       
    // const [reactValue, setReactValue] = useState(value);

    const onBlurHandler = (e: any) => {
        const value = JSON.parse(e.target?.querySelector("textarea").value);
        onChange(id, value);
    };

    //  const splitByLanguageValue: any[] = useMemo(() => Object.entries(value).map(([key, value]) => ({ [key]: value })), [value]);

    return (
        <>
            <div
                onClick={() => { setIsCollapsed(!isCollapsed) }}
                className={subCategoryHeaderContainer}
            >
                <span className={subCategoryHeaderIcon}>
                    <Icon iconName={isCollapsed ? "ChevronRightSmall" : "ChevronDownSmall"} />
                </span>
                <span className={subCategoryHeader}>
                    {"Example en-US (defaults)"}
                </span>
            </div>
            {
                !isCollapsed &&  <TextField className={classNames.textField} readOnly={true}  label="" multiline rows={20} value={JSON.stringify({...DruidWebchat_v2.defaultDruidLocalization["en-US"], ...enUSJson}, null, 5)} />

            }

            <div className={classNames.container}>
                <JsonEditor
                    value={value}

                    onBlur={onBlurHandler}
                    mode={'text'}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        border: '1px black solid',
                        minHeight: 80,
                        maxHeight: 600,
                        overflow: 'auto',
                        outline: 'none'
                    }}
                    textareaClassName={classNames.textarea}
                />
            </div>
        </>

        //     <>
        //     {languages.map((val) => (<div>{val}</div>))}
        //         {
        //             splitByLanguageValue.map((localization) =>
        //             (<JsonEditor
        //                 value={localization}

        //                 onBlur={onBlurHandler}
        //                 mode={'text'}
        //                 // highlight={code => code && highlight(code, languages.js, "json")}
        //                 padding={10}
        //                 style={{
        //                     fontFamily: '"Fira code", "Fira Mono", monospace',
        //                     fontSize: 12,
        //                     border: '1px black solid',
        //                     minHeight: 80,
        //                     maxHeight: 300,
        //                     overflow: 'auto',
        //                     outline: 'none'
        //                 }}
        //                 textareaClassName={classNames.textarea}
        //             />)
        //             )
        //         }


        //         {/* { defaultValue && <PrimaryButton className={classNames.addDefaultTemplateButton} text="Add default template" onClick={() => { onChange(id, defaultValue); setReactValue(defaultValue)}} /> } */}

        //    </>
    );
}