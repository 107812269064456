import { Container } from "react-bootstrap";
import { CustomizationEntry } from "../../Redux/reduxState";
import { Category, SubCategory, UISelectorType } from "../../utilities/types";

const colorAndPaddingEntries: CustomizationEntry[] = [


  {
    id: 'chatTitle',
    displayName: 'Chat Title',
    category: Category.ColorAndPaddings,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "UI"
  },
  {
    id: 'accent',
    displayName: 'Accent',
    category: Category.ColorAndPaddings,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'backgroundColor',
    displayName: 'Background Color',
    category: Category.ColorAndPaddings,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  // { id: 'cardEmphasisBackgroundColor',        
  //   displayName: 'Card Emphasis Background Color',
  //   category: Category.ColorAndPaddings,
  //   uiSelectorType: UISelectorType.colorSelector,
  // },
  {
    id: 'paddingRegular',
    displayName: 'Padding Regular',
    category: Category.ColorAndPaddings,
    uiSelectorType: UISelectorType.integerSelector,
  },
  // { // removed because not used anymore
  //   id: 'paddingWide',
  //   displayName: 'Padding Wide',
  //   category: Category.ColorAndPaddings,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  {
    id: 'subtle',
    displayName: 'Subtle',
    category: Category.ColorAndPaddings,
    uiSelectorType: UISelectorType.colorSelector,
  },
];

const fontEntries: CustomizationEntry[] = [
  {
    id: 'fontSizeSmall',
    displayName: 'Font Size Small',
    category: Category.Fonts,
    uiSelectorType: UISelectorType.percentageSelector,
  },
  // {
  //   id: 'monospaceFont',
  //   displayName: 'Monospace Font',
  //   category: Category.Fonts,
  //   uiSelectorType: UISelectorType.dropDownSelector,
  // },
  {
    id: 'primaryFont',
    displayName: 'Primary Font',
    category: Category.Fonts,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'rootFontSize',
    displayName: 'Root Font Size',
    category: Category.Fonts,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'transcriptFontSize',
    displayName: 'Transcript Font Size',
    category: Category.Fonts,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'headerFontSize',
    displayName: 'Header Font Size',
    category: Category.Fonts,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'sendBoxFontSize',
    displayName: 'SendBox Font Size',
    category: Category.Fonts,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'lineHeight',
    displayName: 'Line Height - in REM',
    category: Category.Fonts,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'messageActivityWordBreak',
    displayName: 'Message Activity Word Break',
    category: Category.Fonts,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
];

const avatarEntries: CustomizationEntry[] = [
  {
    id: 'avatarBorderRadius',
    displayName: 'Avatar Border Radius',
    category: Category.Avatar,
    subCategory: SubCategory.AvatarSettings,
    uiSelectorType: UISelectorType.percentageSelector,
  },
  {
    id: 'avatarSize',
    displayName: 'Avatar Size',
    category: Category.Avatar,
    subCategory: SubCategory.AvatarSettings,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'botAvatarBackgroundColor',
    displayName: 'Background Color',
    category: Category.Avatar,
    subCategory: SubCategory.BotAvatar,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'botAvatarImage',
    displayName: 'Image',
    category: Category.Avatar,
    subCategory: SubCategory.BotAvatar,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  // {
  //   id: 'botAvatarInitials',
  //   displayName: 'Initials',
  //   category: Category.Avatar,
  //   subCategory: SubCategory.BotAvatar,
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
  {
    id: 'userAvatarBackgroundColor',
    displayName: 'Background Color',
    category: Category.Avatar,
    subCategory: SubCategory.UserAvatar,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'userAvatarImage',
    displayName: 'Image',
    category: Category.Avatar,
    subCategory: SubCategory.UserAvatar,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  // {
  //   id: 'userAvatarInitials',
  //   displayName: 'Initials',
  //   category: Category.Avatar,
  //   subCategory: SubCategory.UserAvatar,
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
];

const buttonsEntries: CustomizationEntry[] = [
  {
    id: 'buttonsPadding',
    displayName: 'Padding',
    category: Category.Buttons,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsBorderRadius',
    displayName: 'Border Radius',
    category: Category.Buttons,
    subCategory: SubCategory.Layout,

    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsTextColor',
    displayName: 'Text Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,

    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsBorderColor',
    displayName: 'Border Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,

    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsBackgroundColor',
    displayName: 'Background Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,

    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsBackgroundColorOnHover',
    displayName: 'Hover Background Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsTextColorOnHover',
    displayName: 'Hover Text Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,

    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'buttonsBorderColorOnHover',
    displayName: 'Hover Border Color',
    category: Category.Buttons,
    subCategory: SubCategory.Colors,

    uiSelectorType: UISelectorType.colorSelector,
    containerName: "UI"
  },
  {
    id: 'v2_contextMenu_icon',
    displayName: 'Context menu icon',
    category: Category.Buttons,
    subCategory: SubCategory.Icons,
    uiSelectorType: UISelectorType.defaultSelector,
    multiline: true,
    containerName: "UI"
  },
  {
    id: 'v2_minimize_icon',
    displayName: 'Minimize icon',
    category: Category.Buttons,
    subCategory: SubCategory.Icons,
    uiSelectorType: UISelectorType.defaultSelector,
    multiline: true,
    containerName: "UI"
  },
  {
    id: 'v2_containerElement_expand_icon',
    displayName: 'Expand chat icon',
    category: Category.Buttons,
    subCategory: SubCategory.Icons,
    uiSelectorType: UISelectorType.defaultSelector,
    multiline: true,
    containerName: "UI"
  },
  {
    id: 'v2_containerElement_collapse_icon',
    displayName: 'Collapse chat icon',
    category: Category.Buttons,
    subCategory: SubCategory.Icons,
    uiSelectorType: UISelectorType.defaultSelector,
    multiline: true,
    containerName: "UI"
  }
  
];
const chatBubbleEntries: CustomizationEntry[] = [
  {
    id: 'bubbleBackground',
    displayName: 'Background',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleTextColor',
    displayName: 'Text Color',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleBorderColor',
    displayName: 'Border Color',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleBorderRadius',
    displayName: 'Border Radius',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleBorderStyle',
    displayName: 'Border Style',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'bubbleBorderWidth',
    displayName: 'Border Width',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleNubSize',
    displayName: 'Nub Size',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleNubOffset',
    displayName: 'Nub Offset',
    category: Category.ChatBubble,
    subCategory: SubCategory.AvatarChatBubble,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'bubbleFromUserBackground',
    displayName: 'Background',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleFromUserTextColor',
    displayName: 'Text Color',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleFromUserBorderColor',
    displayName: 'Border Color',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'bubbleFromUserBorderRadius',
    displayName: 'Border Radius',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleFromUserBorderStyle',
    displayName: 'Border Style',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'bubbleFromUserBorderWidth',
    displayName: 'Border Width',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleFromUserNubSize',
    displayName: 'Nub Size',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleFromUserNubOffset',
    displayName: 'Nub Offset',
    category: Category.ChatBubble,
    subCategory: SubCategory.UserChatBubble,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'bubbleImageHeight',
    displayName: 'Image Height',
    category: Category.ChatBubble,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleMaxWidth',
    displayName: 'Max Width',
    category: Category.ChatBubble,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleMinHeight',
    displayName: 'Min Height',
    category: Category.ChatBubble,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'bubbleMinWidth',
    displayName: 'Min Width',
    category: Category.ChatBubble,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
];

const sendBoxEntries: CustomizationEntry[] = [
  {
    id: 'sendBoxHeight',
    displayName: 'Height',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'sendBoxMaxHeight',
    displayName: 'Max Height',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'druidSendBoxPaddingLeftRight',
    displayName: 'Padding Left and Right',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'druidSendBoxPaddingTop',
    displayName: 'Padding Top',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'druidSendBoxPaddingBottom',
    displayName: 'Padding Bottom',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },

  {
    id: 'druidSendBoxTextBoxPadding',
    displayName: 'Inner padding',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'druidSendBoxInnerPaddingLeftRight',
    displayName: 'Inner padding Left and Right',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
  {
    id: 'druidSendBoxInnerPaddingTopBottom',
    displayName: 'Inner padding Top and Bottom',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },

  // not used -> hidden by default and should stay hidden
  // { id: 'hideSendBox',        
  //   displayName: 'Hide Send Box',        
  //   category: Category.SendBox,
  //   subCategory: SubCategory.Layout,
  //   uiSelectorType: UISelectorType.booleanSelector,
  // },
  {
    id: 'hideDruidSendBox',
    displayName: 'Hide Send Box',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "UI"
  },
  {
    id: 'hideUploadButton',
    displayName: 'Hide Upload Button',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'sendBoxTextWrap',
    displayName: 'Text Wrap',
    category: Category.SendBox,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'sendBoxTextColor',
    displayName: 'Text Color',
    category: Category.SendBox,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxBackground',
    displayName: 'Background Color',
    category: Category.SendBox,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,

  },
  {
    id: 'sendBoxDisabledTextColor',
    displayName: 'Disabled Text Color',
    category: Category.SendBox,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxPlaceholderColor',
    displayName: 'Placeholder Color',
    category: Category.SendBox,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxButtonColor',
    displayName: 'Default',
    category: Category.SendBox,
    subCategory: SubCategory.ButtonColors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxButtonColorOnDisabled',
    displayName: 'On Disabled',
    category: Category.SendBox,
    subCategory: SubCategory.ButtonColors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxButtonColorOnFocus',
    displayName: 'On Focus',
    category: Category.SendBox,
    subCategory: SubCategory.ButtonColors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxButtonColorOnHover',
    displayName: 'On Hover',
    category: Category.SendBox,
    subCategory: SubCategory.ButtonColors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'microphoneButtonColorOnDictate',
    displayName: 'Microphone Button Color On Dictate',
    category: Category.SendBox,
    subCategory: SubCategory.ButtonColors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'sendBoxBorderBottom',
    displayName: 'Bottom',
    category: Category.SendBox,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'sendBoxBorderLeft',
    displayName: 'Left',
    category: Category.SendBox,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'sendBoxBorderRight',
    displayName: 'Right',
    category: Category.SendBox,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'sendBoxBorderTop',
    displayName: 'Top',
    category: Category.SendBox,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'druidSendBoxInnerBorderRadius',
    displayName: 'Inner Border Radius',
    category: Category.SendBox,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "UI"
  },
];

const suggestedActionsEntries: CustomizationEntry[] = [
  {
    id: 'suggestedActionBackground',
    displayName: 'Background Color',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'suggestedActionTextColor',
    displayName: 'Color',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'suggestedActionDisabledBackground',
    displayName: 'Background Color (Disabled)',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'suggestedActionDisabledTextColor',
    displayName: 'Text Color (Disabled)',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Colors,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'suggestedActionBorderColor',
    displayName: 'Border',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'suggestedActionBorderRadius',
    displayName: 'Radius',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'suggestedActionBorderStyle',
    displayName: 'Style',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'suggestedActionBorderWidth',
    displayName: 'Width',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Border,
    uiSelectorType: UISelectorType.integerSelector,
  },
  // {
  //   id: 'suggestedActionDisabledBackground',
  //   displayName: 'Color',
  //   category: Category.SuggestedActions,
  //   subCategory: SubCategory.BorderDisabled,
  //   uiSelectorType: UISelectorType.colorSelector,
  // },
  {
    id: 'suggestedActionDisabledBorderColor',
    displayName: 'Border',
    category: Category.SuggestedActions,
    subCategory: SubCategory.BorderDisabled,
    uiSelectorType: UISelectorType.colorSelector,
  },
  //MM: NOT USED
  // { 
  //   id: 'suggestedActionDisabledBorderRadius',
  //   displayName: 'Radius',
  //   category: Category.SuggestedActions,
  //   subCategory: SubCategory.BorderDisabled,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  {
    id: 'suggestedActionDisabledBorderStyle',
    displayName: 'Style',
    category: Category.SuggestedActions,
    subCategory: SubCategory.BorderDisabled,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'suggestedActionDisabledBorderWidth',
    displayName: 'Width',
    category: Category.SuggestedActions,
    subCategory: SubCategory.BorderDisabled,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'suggestedActionHeight',
    displayName: 'Height',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  // {
  //   id: 'suggestedActionImageHeight',
  //   displayName: 'Image Height',
  //   category: Category.SuggestedActions,
  //   subCategory: SubCategory.Layout,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  {
    id: 'suggestedActionLayout',
    displayName: 'Layout',
    category: Category.SuggestedActions,
    subCategory: SubCategory.Layout,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  //MM: REMOVED
  // {
  //   id: 'suggestedActionsCarouselFlipperCursor',
  //   displayName: 'Cursor',
  //   category: Category.SuggestedActions,
  //   subCategory: SubCategory.CarouselFlipper,
  //   uiSelectorType: UISelectorType.dropDownSelector,
  // },
  {
    id: 'suggestedActionsCarouselFlipperBoxWidth',
    displayName: 'Box Width',
    category: Category.SuggestedActions,
    subCategory: SubCategory.CarouselFlipper,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'suggestedActionsCarouselFlipperSize',
    displayName: 'Size',
    category: Category.SuggestedActions,
    subCategory: SubCategory.CarouselFlipper,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'suggestedActionsStackedHeight',
    displayName: "Max Height (0 value for auto)",
    category: Category.SuggestedActions,
    subCategory: SubCategory.Container,
    uiSelectorType: UISelectorType.integerSelector,
  },
  // MM: REMOVED
  // {
  //   id: 'suggestedActionsStackedOverflow',
  //   displayName: "Overflow Setting",
  //   category: Category.SuggestedActions,
  //   subCategory: SubCategory.Container,
  //   uiSelectorType: UISelectorType.dropDownSelector,
  // },
];

const timestampAndStatusEntries: CustomizationEntry[] = [
  {
    id: 'groupTimestamp',
    displayName: "Group Timestamp",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'sendTimeout',
    displayName: "Send Timeout",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'sendTimeoutForAttachments',
    displayName: "Send Timeout for Attachments",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'timestampColor',
    displayName: "Color",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'timestampFormat',
    displayName: "Format",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'customTimestampFormat',
    displayName: "Custom Timestamp Format",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.TimeStamp,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  //// STATUS
  {
    id: 'activityStatusReadColor',
    displayName: "Status Read Color",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.Status,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'activityStatusBotName',
    displayName: "Status Bot Name",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.Status,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'activityStatusShowBotName',
    displayName: "Show Bot Name",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.Status,
    containerName: "UI",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'activityStatusUserName',
    displayName: "Status User Name",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.Status,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },

  {
    id: 'activityStatusShowUserName',
    displayName: "Show User Name",
    category: Category.TimestampAndStatus,
    subCategory: SubCategory.Status,
    containerName: "UI",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  
];

const trascriptOverlayButtonsEntries: CustomizationEntry[] = [
  {
    id: 'transcriptOverlayButtonColor',
    displayName: "Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonDefault,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'transcriptOverlayButtonBackground',
    displayName: "Background Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonDefault,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  // {
  //   id: 'newMessagesButtonFontSize',
  //   displayName: "New Message Button Font Size",
  //   category: Category.TranscriptOverlayButtons,
  //   subCategory: SubCategory.ButtonDefault,
  //   uiSelectorType: UISelectorType.percentageSelector,
  // },
  {
    id: 'transcriptOverlayButtonColorOnFocus',
    displayName: "Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonOnFocus,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'transcriptOverlayButtonBackgroundOnFocus',
    displayName: "Background Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonOnFocus,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'transcriptOverlayButtonColorOnHover',
    displayName: "Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonOnHover,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'transcriptOverlayButtonBackgroundOnHover',
    displayName: "Background Color",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ButtonOnHover,
    uiSelectorType: UISelectorType.rgbaSelector,
  },
  {
    id: 'scrollToEndButtonBehavior',
    displayName: "Behavior",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ScrollToEndButton,
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'scrollToEndButtonFontSize',
    displayName: "Button Font Size",
    category: Category.TranscriptOverlayButtons,
    subCategory: SubCategory.ScrollToEndButton,
    uiSelectorType: UISelectorType.percentageSelector,
  },
  
];

const connectivityUIEntries: CustomizationEntry[] = [
  // {
  //   id: 'connectivityIconPadding',
  //   displayName: "Icon Padding",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'connectivityMarginLeftRight',
  //   displayName: "Margin - Left and Right",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'connectivityMarginTopBottom',
  //   displayName: "Margin - Top and Bottom",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'connectivityTextSize',
  //   displayName: "Text Size",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.percentageSelector,
  // },
  // {
  //   id: 'failedConnectivity',
  //   displayName: "Failed Connectivity Color",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.colorSelector,
  // },
  // {
  //   id: 'slowConnectivity',
  //   displayName: "Slow Connectivity Color",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.colorSelector,
  // },
  // {
  //   id: 'notificationText',
  //   displayName: "Notification Text Color",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.ConnectivityStyling,
  //   uiSelectorType: UISelectorType.colorSelector,
  // },
  // {
  //   id: 'typingAnimationBackgroundImage',
  //   displayName: "Background Image (url)",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.TypingAnimation,
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
  // {
  //   id: 'typingAnimationDuration',
  //   displayName: "Duration",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.TypingAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'typingAnimationHeight',
  //   displayName: "Height",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.TypingAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'typingAnimationWidth',
  //   displayName: "Width",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.TypingAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'spinnerAnimationBackgroundImage',
  //   displayName: "Background Image (url)",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.SpinnerAnimation,
  //   uiSelectorType: UISelectorType.dropDownSelector,
  // },
  // {
  //   id: 'spinnerAnimationHeight',
  //   displayName: "Height",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.SpinnerAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'spinnerAnimationWidth',
  //   displayName: "Width",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.SpinnerAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'spinnerAnimationPadding',
  //   displayName: "Padding",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.SpinnerAnimation,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'enableUploadThumbnail',
  //   displayName: "Enable",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.UploadThumbnail,
  //   uiSelectorType: UISelectorType.booleanSelector,
  // },
  // {
  //   id: 'uploadThumbnailContentType',
  //   displayName: "Type",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.UploadThumbnail,
  //   uiSelectorType: UISelectorType.dropDownSelector,
  // },
  // {
  //   id: 'uploadThumbnailHeight',
  //   displayName: "Height",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.UploadThumbnail,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'uploadThumbnailWidth',
  //   displayName: "Width",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.UploadThumbnail,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'uploadThumbnailQuality',
  //   displayName: "Quality",
  //   category: Category.ConnectivityUI,
  //   subCategory: SubCategory.UploadThumbnail,
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
  {
    id: 'slowConnectionAfter',
    displayName: "Slow Connection After",
    category: Category.ConnectivityUI,
    subCategory: SubCategory.SlowConnectionTimeOut,
    uiSelectorType: UISelectorType.integerSelector,
  },
];

const toastUIEntries: CustomizationEntry[] = [
  {
    id: 'hideToaster',
    displayName: "Hide Toaster",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterLayout,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'toasterHeight',
    displayName: "Height",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterLayout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'toasterMaxHeight',
    displayName: "Max Height",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterLayout,
    uiSelectorType: UISelectorType.integerSelector,
  },
  // {
  //   id: 'toasterSingularMaxHeight',
  //   displayName: "Singular Max Height",
  //   category: Category.ToastUI,
  //   subCategory: SubCategory.ToasterLayout,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  {
    id: 'toastFontSize',
    displayName: "Font Size",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterStyling,
    uiSelectorType: UISelectorType.percentageSelector,
  },
  {
    id: 'toastIconWidth',
    displayName: "Icon Width",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterStyling,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'toastSeparatorColor',
    displayName: "Separator Color",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterStyling,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastTextPadding',
    displayName: "Text Padding",
    category: Category.ToastUI,
    subCategory: SubCategory.ToasterStyling,
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'toastErrorColor',
    displayName: "Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Error,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastErrorBackgroundColor',
    displayName: "Background Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Error,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastInfoColor',
    displayName: "Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Info,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastInfoBackgroundColor',
    displayName: "Background Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Info,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastSuccessColor',
    displayName: "Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Success,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastSuccessBackgroundColor',
    displayName: "Background Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Success,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastWarnColor',
    displayName: "Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Warn,
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'toastWarnBackgroundColor',
    displayName: "Background Color",
    category: Category.ToastUI,
    subCategory: SubCategory.Warn,
    uiSelectorType: UISelectorType.colorSelector,
  },
  //MM: REMOVED
  // {
  //   id: 'notificationDebounceTimeout',
  //   displayName: "Notification Debounce Timeout",
  //   category: Category.ToastUI,
  //   subCategory: SubCategory.NotificationTimeout,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
];

const miscellaneousEntries: CustomizationEntry[] = [
  {
    id: 'richCardWrapTitle',
    displayName: 'Rich Card Wrap Title',
    category: Category.Miscellaneous,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  // {
  //   id: 'rootHeight',
  //   displayName: 'Root Height',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.percentageSelector,
  // },
  // {
  //   id: 'rootWidth',
  //   displayName: 'Root Width',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.percentageSelector,
  // },
  // {
  //   id: 'rootZIndex',
  //   displayName: 'Root Index',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'hideScrollToEndButton',
  //   displayName: 'Hide Scroll To End Button',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.booleanSelector,
  // },
  // {
  //   id: 'showSpokenText',
  //   displayName: 'Show Spoken Text',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.booleanSelector,
  // },
  // {
  //   id: 'videoHeight',
  //   displayName: "Video Height",
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.integerSelector,
  // },
  // {
  //   id: 'emojiSet',
  //   displayName: "Emoji Set",
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
  // {
  //   id: 'markdownRespectCRLF',
  //   displayName: 'Markdown Respect CRLF',
  //   category: Category.Miscellaneous,
  //   uiSelectorType: UISelectorType.booleanSelector,
  // },
];
const layoutEntries: CustomizationEntry[] = [
  // height?: Nullable<string | number>,
  //   width?: Nullable<string | number>,
  //   minWidth?: Nullable<string | number>,
  {
    id: 'autoExpandEnabled',
    displayName: 'Auto Expand',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'closeButtonEnabled',
    displayName: 'Show default Close Button',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'height',
    displayName: 'Chat Height',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'width',
    displayName: 'Chat Width',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'minWidth',
    displayName: 'Chat Minimum Width',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'position',
    displayName: 'Position (left/right)',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.dropDownSelector,
  },
  {
    id: 'floatRight',
    displayName: 'Float',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'bottom',
    displayName: 'Bottom',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'top',
    displayName: 'Top',
    category: Category.Layout,
    subCategory: SubCategory.Common,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  //// CHAT BOX
  {
    id: 'chatBoxBorderRadius',
    displayName: 'Border Radius',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'chatBoxBottomRadius',
    displayName: 'Bottom Radius',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'chatBoxBorderColor',
    displayName: 'Border Color',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'transcriptLinkColor',
    displayName: 'Link Color',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'chatBoxBorderSize',
    displayName: 'Border Size',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.integerSelector,
  },
  {
    id: 'resizableEnabled',
    displayName: 'Enable Resize',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    containerName: "UI",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'autoScrollSnapOnPage',
    displayName: 'Auto Scroll',
    category: Category.Layout,
    subCategory: SubCategory.ChatBox,
    uiSelectorType: UISelectorType.booleanSelector,
  },
  //// HEADER
  {
    id: 'headerTextColor',
    displayName: 'Text Color',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'headerTextColorOnHover',
    displayName: 'Hover Text Color',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'headerButtonColorOnFocus',
    displayName: 'Focus Button Color',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  // {
  //   id: 'headerFontSize',
  //   displayName: 'Font Size',
  //   category: Category.Layout,
  //   subCategory: SubCategory.Header,
  //   containerName: "UI",
  //   uiSelectorType: UISelectorType.defaultSelector,
  // },
  {
    id: 'headerBottomBorderRadius',
    displayName: 'Bottom Border Radius',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'headerOuterPadding',
    displayName: 'Outer Padding',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.defaultSelector,
  },
  {
    id: 'headerBackground',
    displayName: 'Background Color',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI",
    uiSelectorType: UISelectorType.colorSelector,
  },
  {
    id: 'showCloseButton',
    displayName: 'Show Close Button',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showChangeSideButton',
    displayName: 'Show Change Side Button',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showLanguageSelector',
    displayName: 'Show Language Selector Button',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showAvatar',
    displayName: 'Header - Show Avatar',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showVoiceInputSelector',
    displayName: 'Show Voice Input Selector',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showReload',
    displayName: 'Header - Show Reload Button',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
  {
    id: 'showReset',
    displayName: 'Header - Show Reset Button',
    category: Category.Layout,
    subCategory: SubCategory.Header,
    containerName: "UI.headerOptions",
    uiSelectorType: UISelectorType.booleanSelector,
  },
];
const customCSSEntries: CustomizationEntry[] = [
  {
      id: 'customDruidStyleSet',
      displayName: 'Druid StyleSet - JSON',
      category: Category.CustomCSS,
      containerName: "root",
      uiSelectorType: UISelectorType.defaultSelector,
      multiline: true
  }
]

const customHandlers: CustomizationEntry[] = [
  {
    id: 'webChatEventHandler',
    displayName: 'Webchat Event Handlers',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "root",
    multiline: true,
    defaultValue: 
    `(action, currentDruidState) => {
      if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && DruidWebchat_v2.isNewActivity(currentDruidState, action.payload.activity)) {
        if(action.payload.activity.type === "event" && action.payload.activity.name == "disableEvent") {
          DruidWebchat_v2.disableChat();
        }
      }
    }`
  },
  {
    id: 'activityStatusMiddleware',
    displayName: 'ActivityStatus Middleware',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "middlewares",
    multiline: true,
    defaultValue: "({ dispatch }) => next => (args)=> (<> { next(args) }</>)"
  },
  {
    id: 'cardActionMiddleware',
    displayName: 'CardAction Middleware',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "middlewares",
    multiline: true,
    defaultValue: `({ dispatch }) => next => (args) => {
      const { cardAction} = args;
      console.log(\`clicked \${cardAction.value}\`);
      return next(args);
    }`
  },
  {
    id: 'attachmentMiddleware',
    displayName: 'Attachment Middleware',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "middlewares",
    multiline: true,
    defaultValue: "({ dispatch }) => next => (args)=> (<> { next(args) } </>)"

  },
  {
    id: 'activityMiddleware',
    displayName: 'Activity Middleware',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "middlewares",
    multiline: true,
    defaultValue: ` () => next => (...setupArgs) => {
      const [card] = setupArgs;
      const activity = card.activity;
      if ( activity.type == 'message'  ) {
        return (...renderArgs) => (
          (<>
            {
              next(card)(...renderArgs)
            }
          </>)
        );
      }
      return next(...setupArgs);
    }`

  },
  {
    id: 'scrollToEndButtonMiddleware',
    displayName: 'ScrollToEnd Button Middleware',
    category: Category.Handlers,
    uiSelectorType: UISelectorType.reactSelector,
    containerName: "root",
    multiline: true,
    defaultValue: `() => (next) => (args)=> (props) => {
      const nextRender = next(args);
      return nextRender ? <>{next(args)(props)}</> : <></>;
    }`
  }
];

const generalEntries: CustomizationEntry[] = [
  {
    id: 'isDisabled',
    displayName: 'Disable webchat',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'queryParams',
    displayName: 'queryParams',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'backgroundTrafficEnabled',
    displayName: 'Background Traffic Enabled',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'authorizationUrl',
    displayName: 'authorizationUrl',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'authorizationBaseUrl',
    displayName: 'authorizationBaseUrl',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'withCredentials',
    displayName: 'Calls withCredentials',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'withCredentials_authorization',
    displayName: 'Authorization call withCredentials',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'notificationsEnabled',
    displayName: 'Enable Notifications',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'otherServicesEndpointIndex',
    displayName: 'Other Services Endpoint Index',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.integerSelector,
    containerName: "root"
  },
  {
    id: 'heartBeatUrl',
    displayName: '\'Heartbeat\' Url',
    category: Category.General,
    subCategory: SubCategory.Common,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  // {
  //   id: 'userId',
  //   displayName: 'userId',
  //   category: Category.General,
  //   uiSelectorType: UISelectorType.defaultSelector,
  //   containerName: "root"
  // },
  // {
  //   id: 'username',
  //   displayName: 'username',
  //   category: Category.General,
  //   uiSelectorType: UISelectorType.defaultSelector,
  //   containerName: "root"
  // },
  //// COOKIES
 
  {
    id: 'conversationTokenEnabled',
    displayName: 'conversationTokenEnabled',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'druidCookiesExpirationInDays',
    displayName: 'druidCookiesExpirationInDays - Can be fractional (ex: 1/48 for 30minutes)',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'conversationToken_namePrefix',
    displayName: 'conversationToken_namePrefix',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'druidWebChatIsOpenedEnabled',
    displayName: 'druidWebChatIsOpenedEnabled',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'druidWebChatIsOpened_namePrefix',
    displayName: 'druidWebChatIsOpened_namePrefix',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
  {
    id: 'druidCookieConsentEnabled',
    displayName: 'druidCookieConsent',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.booleanSelector,
    containerName: "root"
  },
  {
    id: 'cookieDomain',
    displayName: 'cookieDomain',
    category: Category.General,
    subCategory: SubCategory.Cookies,
    uiSelectorType: UISelectorType.defaultSelector,
    containerName: "root"
  },
];

const localizationEntry: CustomizationEntry = {
  id: 'localization',
  displayName: 'Localization',
  category: Category.Localization,
  uiSelectorType: UISelectorType.localizationSelector,
  containerName: "root"
};

const searchEntry: CustomizationEntry = {
  id: 'search',
  displayName: 'Search',
  category: Category.Search,
  uiSelectorType: UISelectorType.searchSelector,
  containerName: "root"
};

export const customizationEntries: CustomizationEntry[] = [
  ...colorAndPaddingEntries,
  ...fontEntries,
  ...avatarEntries,
  ...buttonsEntries,
  ...chatBubbleEntries,
  ...sendBoxEntries,
  ...suggestedActionsEntries,
  ...timestampAndStatusEntries,
  ...trascriptOverlayButtonsEntries,
  ...connectivityUIEntries,
  ...toastUIEntries,
  ...miscellaneousEntries,
  ...layoutEntries,
  ...customCSSEntries,
  ...customHandlers,
  ...generalEntries,
  searchEntry,
  localizationEntry,
 
];
