import { IAppState, initialAppState, WebchatOptions } from "../reduxState";
import { GenericAction, actionTypes } from "../actions";
import * as merge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';

const simpleUpdateIn = require("simple-update-in");
export type ContainerName = "styleOptions" | "UI" | "root" | "middlewares" | "localization" | "languages" | "UI.headerOptions";
declare const DruidWebchat_v2: any;


export function updateStyleElement(state: IAppState, styleElementName: string, value: any, parentObject: ContainerName = "styleOptions"): IAppState {
  // const parentObject:  ContainerName = "styleOptions";
  // return simpleUpdateIn(state, [parentObject, styleElementName], () => value);

  if (styleElementName === "customDruidStyleSet") {
    try {
      value = JSON.parse(value || "{}");
    } catch {
      return state;
    }
  }

  if (styleElementName === "druidCookiesExpirationInDays") {
    try {
      value = Number(eval(value)) || 0;
    } catch {
      return state;
    }
  }
  const mergedObj = merge.all<WebchatOptions>([state.webchatOptions, parentObject === "root" ?
    {
      [styleElementName]: value
    }
    :
    parentObject === "UI.headerOptions" ?
      {
        "UI": {
          "headerOptions": {
            [styleElementName]: value
          }
        }
      }
      :
      {
        [parentObject]: {
          [styleElementName]: value
        }
      }
  ], { isMergeableObject: isPlainObject });

  if (styleElementName === "customDruidStyleSet") { // always replace
    mergedObj.customDruidStyleSet = value;
  }

  (async () => {
    const { druidWebChatDispatch } = DruidWebchat_v2.druidWebChatInstance;
    switch (styleElementName) {
      case 'position': {
        return druidWebChatDispatch({
          type: 'SET_SIDE',
          payload: value
        });
      }
      case 'customDruidStyleSet': {
        return druidWebChatDispatch({
          type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: [styleElementName], variableValue: value } //json parsed above
        });
      }
      // case 'druidCookiesExpirationInDays': {
      //   return druidWebChatDispatch({
      //     type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: [styleElementName], variableValue: value} //json parsed above
      //   });
      // }
      case 'webChatEventHandler': {
        return druidWebChatDispatch({
          type: 'SET_MIDDLEWARE_OR_EVENT_HANDLER', payload: { handlerName: [styleElementName], handler: value, withDecorate: true }
        });
      }
      case 'activityStatusMiddleware':
      case 'cardActionMiddleware':
      case 'attachmentMiddleware':
      case 'activityMiddleware':
      case 'scrollToEndButtonMiddleware': {
        return druidWebChatDispatch({
          type: 'SET_MIDDLEWARE_OR_EVENT_HANDLER', payload: { handlerName: [styleElementName], handler: value, withDecorate: false }
        });
      }
      // case 'showChatTitle':
      case 'showCloseButton':
      case 'showVoiceInputSelector':
      case 'showChangeSideButton':
      case 'showLanguageSelector':
      case 'showAvatar':
      case 'showReload':
      case 'showReset': {
        return druidWebChatDispatch({
          type: 'SET_HEADEROPTIONS', payload: { [styleElementName]: value }
        });
      }
      case 'userAvatarImage': {
        // druidWebChatDispatch({
        //   type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: ['styleOptions', 'userAvatarInitials'], variableValue: value ? '': undefined}
        // });
        mergedObj.styleOptions.userAvatarInitials = value ? '' : undefined;
        return druidWebChatDispatch({
          type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: ['styleOptions', 'userAvatarImage'], variableValue: value }
        });
      }
      case 'botAvatarImage': {
        mergedObj.styleOptions.botAvatarInitials = value ? '' : undefined;
        druidWebChatDispatch({
          type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: ['styleOptions', 'botAvatarInitials'], variableValue: value ? '' : undefined }
        });
        return druidWebChatDispatch({
          type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: ['styleOptions', 'botAvatarImage'], variableValue: value }
        });
      }
      default: {
        return druidWebChatDispatch({
          type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: parentObject ? (parentObject === "root" ? [styleElementName] : [...parentObject.split("."), styleElementName]) : [styleElementName], variableValue: value }
        });
      }
    }
  })();

  return {
    ...state,
    webchatOptions: mergedObj
  }
}

export function updateRootStateVariable(state: IAppState, stateVariableName: string, value: any): IAppState {
  return {
    ...state,
    [stateVariableName]: value,
  }
}

export function updateStyleOptions(state: IAppState, webchatOptions: any): IAppState {
  return {
    ...state,
    webchatOptions: merge.all<WebchatOptions>([state.webchatOptions, webchatOptions], { isMergeableObject: isPlainObject }),
    initialized: true
  }
}


export const WebChatReducer = (state: IAppState = initialAppState, action: GenericAction<actionTypes, any>): IAppState => {
  console.info(action);

  switch (action.type) {
    case "UPDATE_STYLE_ELEMENT":
      return updateStyleElement(state, action.payload.styleElementName, action.payload.value, action.payload.containerName);
    case "UPDATE_STYLE_OPTIONS":
      return updateStyleOptions(state, action.payload);
    case "UPDATE_ROOT_WEBCHAT_STATE_VARIABlE":
      return updateRootStateVariable(state, action.payload.propertyName, action.payload.value)
    default:
      return state;
  }
};
