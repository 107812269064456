import React, { Fragment, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from 'redux';
import { CustomizationEntry, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { genericSingleAction, actionTypes } from "../../Redux/actions";
import { UISelectorType } from "../../utilities/types";
import { TextField, MaskedTextField,  } from 'office-ui-fabric-react/lib/TextField';


interface DefaultSelectorProps {
    id: string;
    value: any;
    multiline?: boolean;
    onChange: (styleElementName: string, value: any) => void;
}

export const DefaultSelector = (props: DefaultSelectorProps) => {
    const { id, value, onChange } = props;

    const parsedValue = useMemo(() => {
        if(value && typeof value === "object") {
            try {
                return JSON.stringify(value, null, 2);
            } catch {
                return value;
            }
        }   
        return value;
    }, [value]);
    return (
        <Fragment>
            <TextField multiline={props.multiline} defaultValue={parsedValue} onBlur={(e: any) => {
                let newValue = e.target.value;
                if(newValue && /^\d+$/.test(newValue)) {
                    newValue = parseInt(newValue);
                }
                onChange(id, newValue);
                }}/>
        </Fragment>
    );
}