import React, { ElementRef, useRef, useState } from 'react';
// import {
//   ColorPicker,
//   IColor,
//   IColorPickerStyles,
// } from 'office-ui-fabric-react/lib/index';
import {
  ColorPicker,
  IColor,
  IColorPicker,
  IColorPickerStyles,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { debounce } from 'underscore';


import ColorSelectorModal from './colorSelectorModal';

import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { TextField } from '@fluentui/react';
import { DEFAULT_UNDEFINED_COLOR } from '../constants/defaultStyleOptions';
import { ContainerName } from '../../Redux/reducers/AppReducer';

const classNames = mergeStyleSets({
  column: { marginTop: '16px' },
  parent: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1rem',
    height: '100px',
  }
});

const colorPickerStyles: Partial<IColorPickerStyles> = {
  panel: { padding: 12 },
  root: {
    maxWidth: 300,
    minWidth: 300,
  },
  colorRectangle: { height: 268 },
};

const warningMessage = 'Hexadecimal colors must start with a "#" and can only contain 6 total alphanumeric values from [0-9] and [a-f]';

interface ColorSelectorProps {
  id: string;
  value: any;
  onChange: (styleElementName: string, value: any) => void;
  getStyleOptionValue: (key: any, containerName?: ContainerName) => any;
}

const removeHash = (colorCode: string) => {
  if (colorCode && colorCode.length > 0 && colorCode[0] === '#') {
    return colorCode.slice(1);
  }
  return colorCode;
}

export const ColorSelector = (props: ColorSelectorProps) => {
  const getComputedValue = (value: string): any => {
    if(value === 'Transparent') {
      return '#ffffff00';
    }
    if(value && value.startsWith("$")) {
      
      let retFromStyleOptions = props.getStyleOptionValue(value.replace("$", ""), "styleOptions");
      let retFromUIOptions = props.getStyleOptionValue(value.replace("$", ""), "UI");
      let ret = retFromStyleOptions || retFromUIOptions;
      if((retFromStyleOptions && retFromStyleOptions.startsWith("$")) || (retFromUIOptions && retFromUIOptions.startsWith("$"))) {
        ret = getComputedValue(retFromStyleOptions || retFromUIOptions);
      }
      if(!ret) {
        return DEFAULT_UNDEFINED_COLOR;
      }
      return ret || DEFAULT_UNDEFINED_COLOR;
    } else {  
      return value || DEFAULT_UNDEFINED_COLOR;
    }
  }
  const { id, value, onChange } = props;
  const [textFieldValue, setTextFieldValue] = useState(removeHash(getComputedValue(value)));
  const [isErrorMessageOpen, { setTrue: showErrorMessage, setFalse: hideErrorMessage }] = useBoolean(false);

  const compRef = useRef<HTMLDivElement>(null);
  const isValid = (newValue?: string): boolean => {
                    //  /^#([0-9A-F]{3}){1,2}$/i
    if (newValue && (/^#(([0-9A-F]{3}){1,2}|[0-9A-F]{8})$/i).test(`#${newValue}`)) {
      return true;
    }
    return false;
  }

  const colorFormOnChange = (newValue?: string) => {
    if (isValid(newValue)) {
      setTextFieldValue(newValue as string);
      onChange(id, `#${newValue}`);
      if (isErrorMessageOpen) {
        hideErrorMessage();
      }
    }
    else {
      if (newValue !== null && newValue !== undefined) {
        setTextFieldValue(newValue);
        if (!isErrorMessageOpen) {
          showErrorMessage();
        }
      }
    }
  }

  const updateColor = (colorObj: IColor) => {
    onChange(id, colorObj.str);
    hideErrorMessage();
    setTextFieldValue(colorObj.hex);
    if (compRef.current) {
      if (colorObj.str === '#000000' || colorObj.str === '#ffffff') {
        document.querySelector<HTMLDivElement>('.ms-ColorPicker .ms-ColorPicker-slider') && (document.querySelector<HTMLDivElement>('.ms-ColorPicker-slider')!.style.display = "none");

      } else {
        document.querySelector<HTMLDivElement>('.ms-ColorPicker .ms-ColorPicker-slider') && (document.querySelector<HTMLDivElement>('.ms-ColorPicker-slider')!.style.display = "block");
      }

    }
  }

  const debounceUpdateColor = debounce(updateColor, 150)
 

  return (
    <div className={classNames.parent} ref={compRef}>
      {
        <ColorSelectorModal colorValue={getComputedValue(value)} >
        <ColorPicker
          color={getComputedValue(value)}
          onChange={(e: any, color: IColor) => {
            debounceUpdateColor(color);
          }}
          showPreview
          styles={colorPickerStyles}
          strings={{
            alphaAriaLabel: 'Alpha slider: Use left and right arrow keys to change value, hold shift for a larger jump',
            transparencyAriaLabel: 'Transparency slider: Use left and right arrow keys to change value, hold shift for a larger jump',
            hueAriaLabel: 'Hue slider: Use left and right arrow keys to change value, hold shift for a larger jump',
          }}
          alphaSliderHidden />
      </ColorSelectorModal>
      }

      <div className={classNames.column}>
        <TextField value={textFieldValue} errorMessage={isErrorMessageOpen ? warningMessage : ''} prefix='#' onChange={(e: any, newValue?: string) => { colorFormOnChange(newValue) }} />
      </div>
    </div>
  );
};
