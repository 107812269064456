import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import 'prismjs/themes/prism.css'
import 'jsoneditor-react/es/editor.min.css';

import { createAppStore } from './packages/Redux/appStore';
import { Provider } from 'react-redux';

export let initialOptions: any = null;
export const init = (props: {botId: string, baseUrl: string, botName: string, documentationUrl: string, onSuccess: () => any, onClose: () => any, onSave: (json: any) => any}) => {
  initialOptions = props;
  const rootElem = document.getElementById('webchateditor-root');
  if(rootElem) {
    ReactDOM.unmountComponentAtNode(rootElem);
  }

  const store = createAppStore();
  ReactDOM.render(
    <Provider store={store} key={props.botId}>
      <App {...props}/>
    </Provider>,
    document.getElementById('webchateditor-root')
  );
}
export const reInitialize = () => {
  init(initialOptions);
}


