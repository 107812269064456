import { defaultStyleOptions } from "../webchatEditor/constants/defaultStyleOptions"
import { customizationEntries } from '../webchatEditor/constants/customizationEntries'
import { Category, SubCategory, UISelectorType } from "../utilities/types"
import { ContainerName } from "./reducers/AppReducer";
import * as merge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';

declare const DruidWebchat_v2: any;


export interface CustomizationEntry {
    id: string,
    category: Category,
    subCategory?: SubCategory,
    displayName: string,
    uiSelectorType: UISelectorType // TODO create uiSelectorType interface 
    isAdvancedOption?: boolean,
    containerName?: ContainerName,
    multiline?: boolean,
    defaultValue?: any
}

export interface IAppState {
    // Active category to be rendered in middle pane
    activeCategory: Category,
    // Value of UI elements that determine style option value stored here
    customizationEntries: CustomizationEntry[],
    // Value of current style options stored here
    webchatOptions: WebchatOptions;
    jsonIsInvalid: boolean;
    // Closes Modal for color switch when user selects other color selectors. This will fixes having a modal open for each colorSelector
    displayColorModal: boolean;
    colorValue: string;
    initialized: boolean;
    
}

// TODO: Figure out way to get strongly types style Options obj that maps to https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/component/src/Styles/defaultStyleOptions.js
export interface WebchatOptions {
    styleOptions: any,
    UI: any,
    middlewares: any,
    localization: any,
    languages: any
    customDruidStyleSet: any
  }

  export const loadConfigurationValue =
 {
//   "isDisabled": false,
//   "accessibilityFeaturesEnabled": false,
//   "backgroundTrafficEnabled": true,
//   "conversationTokenEnabled": true,
//   "cookieDomain": "",
//   "druidCookiesExpirationInDays": 1,
//   "druidWebChatIsOpenedEnabled": true,
//   "druidShowGroupTimestamp": 10000,
//   "extendedLocalizedString": {
//     "timeSent": " - %1",
//     "suggestedActionsInputPlaceholder": ""
//   },
//   "instrumentationKey": "",
//   "notificationsEnabled": false,
//   "queryParams": "",
//   "serviceNetworkStatusEnabled": true,
//   "UI": {
//     "autoExpandEnabled": false,
//     "autoScrollEnabled": false,
//     "chatHeaderHeight": "38px",
//     "chatTitle": " Mihai-test-bot",
//     "chatTitle_faIcon": "",
//     "containerElement": null,
//     "contextMenuIcon": "fa-plus-circle",
//     "druidLayoutVersionDecorator": "druid-v2",
//     "expandWidth": "unset",
//     "floatRight": "10%",
//     "failedToLoadMessage": "Failed to load",
//     "fullScreenMode": false,
//     "fullScreenMode_leftRightClass": "col-md-3",
//     "fullScreenMode_mainChatClass": "col-md-6",
//     "goBackButton": false,
//     "height": "90vh",
//     "iconCloseChat": "fa-angle-down",
//     "iconSendSvgType": 2,
//     "inputIconsAlignment": "left",
//     "inputPlaceholder": "Type your message here...",
//     "loadingOverlayActive": true,
//     "minWidth": "25vw",
//     "position": "right",
//     "typingMessageBackground": null,
//     "visibleAtScrollTop": false,
//     "width": "430px",
//     "headerOptions": {
//       "showAvatar": true
//     }
//   },
//   "disabled": false,
//   "styleOptions": {
//     "accent": "olive"
//   },
//   "webChatEventHandler": "(action, currentDruidState) => {if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && DruidWebchat_v2.isNewActivity(currentDruidState, action.payload.activity)) {if(action.payload.activity.text == \"disable\") {console.error('gigel')}}}",
//   "middlewares": {},
//   "languages": [
//     {
//       "displayName": "Arabic",
//       "nativeName": "العربية",
//       "name": "ar",
//       "isDefault": false,
//       "icon": "famfamfam-flags sa",
//       "id": 2
//     },
//     {
//       "displayName": "Croatian",
//       "nativeName": "hrvatski",
//       "name": "hr",
//       "isDefault": false,
//       "icon": "famfamfam-flags hr",
//       "id": 24
//     },
//     {
//       "displayName": "Danish",
//       "nativeName": "dansk",
//       "name": "da",
//       "isDefault": false,
//       "icon": "famfamfam-flags dk",
//       "id": 26
//     },
//     {
//       "displayName": "English (United States)",
//       "nativeName": "English (United States)",
//       "name": "en-US",
//       "isDefault": false,
//       "icon": "famfamfam-flags us",
//       "id": 1
//     },
//     {
//       "displayName": "Portuguese",
//       "nativeName": "português",
//       "name": "pt",
//       "isDefault": false,
//       "icon": "famfamfam-flags pt",
//       "id": 20
//     },
//     {
//       "displayName": "Romanian",
//       "nativeName": "română",
//       "name": "ro",
//       "isDefault": true,
//       "icon": "famfamfam-flags ro",
//       "id": 15
//     }
//   ]
}

export const defaultWebchatOptions = (mergeWith: Partial<WebchatOptions> = {}) => merge.all<WebchatOptions>([DruidWebchat_v2.webChatDefaultOptions, loadConfigurationValue, mergeWith], { isMergeableObject: isPlainObject });

export const initialAppState: IAppState = {
    activeCategory: Category.General,
    customizationEntries: customizationEntries,
    // webchatOptions: {
    //     styleOptions: {
    //         ...defaultStyleOptions, 
    //         ...DruidWebchat_v2.overridenBFStyleOptions
    //     },
    //     UI: DruidWebchat_v2.getMe
    // },
    webchatOptions: defaultWebchatOptions(),
    jsonIsInvalid: false,
    displayColorModal: false,
    colorValue: '#ffffff',
    initialized: false
}
