import React from "react";
import { CustomizationEntry, defaultWebchatOptions, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { connect } from "react-redux";
import { actionTypes, genericSingleAction } from "../../Redux/actions";
import { Dispatch, AnyAction } from 'redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { defaultStyleOptions } from "../constants/defaultStyleOptions";
import {
    MessageBar,
    MessageBarType,

} from 'office-ui-fabric-react';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { mergeStyleSets } from '@uifabric/merge-styles';
import { diff } from 'deep-object-diff';
import { customizationEntries } from "../constants/customizationEntries";
import { reInitialize } from "../../..";

////////////////// Styling //////////////////
const classes = mergeStyleSets({
    resetButtonClassName: {
        float: 'right',
        marginTop: '20px',
        height: 35
    },
    copyPasteButtonClassName: {
        marginTop: '20px',
        marginRight: '20px'
    },
    listItem :{
    },
    errors: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        maxHeight: '60px',
        paddingTop: '5px',
    },
    textField: {
        '.ms-TextField-fieldGroup': {
            border: 'none',
            ':hover': {
                border: `1px solid #70B244`,
                borderRadius: 4
            },
            '::after': {
                border: 'none'
            }
        },
        textarea: {
            resize: 'none',
            borderRadius: 4,
            border: '1px solid #B2B2B2',
        }
    },
    '.ms-TextField-fieldGroup': {
        border: 'none',
        
        '.ms-TextField-prefix': {
            borderRadius: 4
        }
        // border: '1px solid red',
    },
});

////////////////// WebChatJsonEditor //////////////////
interface StateProps {
    currentStyleOptions: Partial<WebchatOptions>;
    jsonIsInvalid: boolean;
    customizationEntries: CustomizationEntry[];
}

interface DispatchProps {
    updateStyleOptions: (styleOptions: WebchatOptions) => void,
    updateRootStateVariable: (stateVariableName: string, value: any) => void
}

interface Props {
    onSuccess: () => any
}

interface LocalState {
    textValue: string,
    uniqueErrors: Set<String>,

}

type PropsType = StateProps & DispatchProps & Props;

export class WebChatJsonEditor extends React.Component<PropsType, LocalState> {
    constructor(props: PropsType) {
        super(props);
        this.state = ({
            textValue: JSON.stringify(this.props.currentStyleOptions, null, 4),
            uniqueErrors: new Set(),
        })
    }

    private onJsonChange = (event: any, newJson?: string) => {
        if (newJson) {
            this.setState({ textValue: newJson });
            var newStyleOptions: WebchatOptions
            try {
                newStyleOptions = JSON.parse(newJson);
                let invalidAttributesInJson = this.validateJSON(newStyleOptions);
                if (!invalidAttributesInJson) {
                    this.props.updateRootStateVariable('webchatOptions', newStyleOptions);
                }
                this.props.updateRootStateVariable('jsonIsInvalid', false);
            }
            catch {
                this.props.updateRootStateVariable('jsonIsInvalid', true);
            }
        }
    }

    private renderErrorBanner = () => {
        if (this.props.jsonIsInvalid) {
            return (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                >
                    Invalid Json!
                </MessageBar>)
        }
    }

    private resetToDefault = (event: any) => {
        // eslint-disable-next-line no-restricted-globals
        confirm('Are you sure? All current customizations will revert to previous save!');
        this.state.uniqueErrors.clear();
        this.setState({ 
            textValue: JSON.stringify({}, null, 4),
            uniqueErrors: this.state.uniqueErrors,
        });
        this.props.updateRootStateVariable('jsonIsInvalid', false);
        this.props.updateRootStateVariable('webchatOptions', defaultWebchatOptions());

        reInitialize();
    }

    private removeError = (index: number) => {
        const { uniqueErrors } = this.state;
        uniqueErrors.delete(customizationEntries[index].id)
        this.setState({
            uniqueErrors: uniqueErrors
        })
        return true;
    }

    private addError = (index: number) => {
        const { uniqueErrors } = this.state;
        if (!uniqueErrors.has(customizationEntries[index].id)) {
            var addNewError = uniqueErrors.add(customizationEntries[index].id)
            this.setState({
                uniqueErrors: addNewError,
            })
        }
        return false;
    }

    private checkColor = (id: string, index: number) => {
        if ((/^#([0-9A-F]{3}){1,2}$/i).test(`${id}`)) {
            return this.removeError(index);
        } else {
            return this.addError(index)
        }
    }

    private checkRGBA = (id: string, index: number) => {
        if ((/rgba?\((\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*)((?:,\s*[0-9.]*\s*)?)\)/g).test(id)) {
            return this.removeError(index);
        } else {
            return this.addError(index)
        }
    }

    private checkInt = (id: number, index: number) => {
        if (typeof id === 'number') {
            return this.removeError(index);
        } else {
            return this.addError(index)
        }
    }

    private checkBoolean = (id: string, index: number) => {
        if (typeof id === 'boolean') {
            return this.removeError(index);
        } else {
            return this.addError(index)
        }
    }

    private checkPercentage = (id: string, index: number) => {
        if ((/^[0]*?(?<Percentage>[1-9][0-9]?|100)%?$/i).test(id)) {
            return this.removeError(index);
        } else {
            return this.addError(index)
        }
    }
    //TODO: complete dropdown validation

    checkDropDown = (id: string, index: number) => {
        console.log(id)
    }

    //TODO: complete default validation

    checkDefault = (id: string, index: number) => {
        console.log(id)

    }

    private validateJSON = (newJson: any): boolean => {
        let invalidAttributesInJson = false;
        this.props.customizationEntries.map((entry, index) => {
            switch (entry.uiSelectorType) {
                case 'colorSelector':
                    if (this.checkColor(newJson[entry.id], index)){
                        return true;
                    } else {
                        invalidAttributesInJson = true;
                        return false;
                    }
                case 'booleanSelector':
                    if (this.checkBoolean(newJson[entry.id], index)){
                        return true;
                    } else {
                        invalidAttributesInJson = true;
                        return false;
                    }
                case 'rgbaSelector':
                    if (this.checkRGBA(newJson[entry.id], index)){
                        return true;
                    } else {
                        invalidAttributesInJson = true;
                        return false;
                    }
                case 'integerSelector':
                    if (this.checkInt(newJson[entry.id], index)){
                        return true;
                    } else {
                        invalidAttributesInJson = true;
                        return false;
                    }
                case 'percentageSelector':
                    if (this.checkPercentage(newJson[entry.id], index)){
                        return true;
                    } else {
                        invalidAttributesInJson = true;
                        return false;
                    }
                // return true;
                case 'defaultSelector':
                    // return this.checkDefault(newJson[entry.id], index);
                    return true;
                case 'dropDownSelector':
                    // return this.checkDropDown(newJson[entry.id], index);
                    return true;
                default:
                    return true;
            }
        });
        return invalidAttributesInJson;

    }

    render() {
        let newArr = Array.from(this.state.uniqueErrors)
        return (
            <div>
                {this.renderErrorBanner()}
                {
                    newArr.length ?
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                        > 
                            The following id's may have incorrect values. Please correct before proceeding
                            <div className={classes.errors}>
                                {newArr.map(error => (<li className={classes.listItem}>{error}</li>))}
                            </div>
                        </MessageBar>
                        :
                        null
                }
                <TextField className={classes.textField} readOnly={true} onChange={(event: any, newValue?: string) => this.onJsonChange(event, newValue)} label="" multiline rows={20} value={this.state.textValue} />
                <DefaultButton className={`btn btn-secondary ${classes.copyPasteButtonClassName}`} onClick={() => { this.props.onSuccess && this.props.onSuccess();navigator.clipboard.writeText(this.state.textValue);}} text="Copy" />
                <DefaultButton className={`btn btn-secondary ${classes.copyPasteButtonClassName}`}
                    onClick={async () => {
                        try {
                            const newText = await navigator.clipboard.readText();
                            const pastedStyleOptions = JSON.parse(newText);
                            this.props.updateStyleOptions(pastedStyleOptions);
                            this.setState((state) => ({ ...state, textValue: newText }));
                            this.props.onSuccess && this.props.onSuccess();
                        } catch(ex) {

                        }
                       
                    }
                    }
                    text="Paste" />

                <PrimaryButton className={`btn btn-primary ${classes.resetButtonClassName}`} onClick={(event: any) => { this.resetToDefault(event) }} text="Reset to previous" />
                <br/>
                <br/>

                <strong> When you paste the code, don't forget to manually click save in order for the changes to take effect! </strong>
                
            </div>
        );
    }
}

////////////////// Redux //////////////////

export const transformWebchatOptions = (webchatOptions: any) => {
    delete webchatOptions.languages;
    return webchatOptions;
}
const mapStateToProps = (state: IAppState, ownProps: Props): StateProps => ({
    currentStyleOptions: { ...diff(defaultWebchatOptions(), transformWebchatOptions(state.webchatOptions)), customDruidStyleSet: state.webchatOptions.customDruidStyleSet },
    jsonIsInvalid: state.jsonIsInvalid,
    customizationEntries: state.customizationEntries,


});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
    updateStyleOptions: (webchatOptions: WebchatOptions) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_STYLE_OPTIONS, webchatOptions ));
    },
    updateRootStateVariable: (stateVariableName: string, value: any) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_ROOT_WEBCHAT_STATE_VARIABlE, { propertyName: stateVariableName, value: value }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebChatJsonEditor);
