import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from 'redux';
import { CustomizationEntry, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { genericSingleAction, actionTypes } from "../../Redux/actions";
import { UISelectorType } from "../../utilities/types";
import { DefaultSelector } from "./defaultSelector";
import { BooleanSelector } from "./booleanSelector";
import { IntegerSelector } from "./integerSelector";
import { PercentageSelector } from "./percentageSelector";
import { ColorSelector } from "./colorSelector";
import { RgbaSelector } from './rgbaSelector'

import { mergeStyles } from "@fluentui/react";
import { DropDownSelector } from "./dropDownSelector";
import { ContainerName } from "../../Redux/reducers/AppReducer";
import { DEFAULT_UNDEFINED_RGBA } from "../constants/defaultStyleOptions";
import { ReactSelector } from "./reactSelector";
import { LocalizationSelector } from "./localizationSelector";
import WebChatOptionsSearch from "./WebChatOptionsSearch";


const UI_selector = mergeStyles(
    {
        fontSize: '15px',
        fontWeight: '400',
        paddingBottom: 15
    }
);

interface CustomizationEntrySelectorProps {
    entry: CustomizationEntry;
    value: any;
    onChange: (styleElementName: string, value: any, containerName?: ContainerName) => void;
    getStyleOptionValue: (key: any, containerName?: ContainerName) => any;
}

export const CustomizationEntrySelector = (props: CustomizationEntrySelectorProps) => {
    const { entry, value, onChange, } = props;
    const { displayName, id } = entry;

    const onChangeWithContainerName = (styleElementName: string, value: any) => {
        props.onChange(styleElementName, value, entry.containerName);
    }

    const renderSelector = (type: UISelectorType) => {
        switch (type) {
            case UISelectorType.booleanSelector:
                return <BooleanSelector id={id} onChange={onChangeWithContainerName} value={value} />
            case UISelectorType.integerSelector:
                return <IntegerSelector id={id} onChange={onChangeWithContainerName} value={value} />
            case UISelectorType.percentageSelector:
                return <PercentageSelector id={id} onChange={onChangeWithContainerName} value={value} />
            case UISelectorType.colorSelector:
                return <ColorSelector id={id} onChange={onChangeWithContainerName} value={value} getStyleOptionValue={props.getStyleOptionValue} />
            case UISelectorType.dropDownSelector:
                return <DropDownSelector id={id} onChange={onChangeWithContainerName} value={value} />
            case UISelectorType.rgbaSelector:
                return <RgbaSelector id={id} onChange={onChangeWithContainerName} value={value || DEFAULT_UNDEFINED_RGBA} />
            case UISelectorType.reactSelector:
                return <ReactSelector id={id} onChange={onChangeWithContainerName} value={value || ""} defaultValue={entry.defaultValue} />
            case UISelectorType.localizationSelector:
                return <LocalizationSelector 
                    id={id} onChange={onChangeWithContainerName} 
                    value={value || ""} defaultValue={entry.defaultValue} 
                    languages={props.getStyleOptionValue("languages", "root")}
                    />
            case UISelectorType.searchSelector:
                return <WebChatOptionsSearch />
            case UISelectorType.defaultSelector:
            default:
                return <DefaultSelector multiline={entry.multiline} id={id} onChange={onChangeWithContainerName} value={value} />
        }
    }

    return (
        <div className={UI_selector}>
            {displayName !== "Search" && displayName} {displayName !== "Search" && <strong> ({id}) </strong>} 

            {renderSelector(entry.uiSelectorType)}
        </div>
    );
}
