import React from "react";
import { withRouter } from "react-router-dom";
import { CustomizationEntry, defaultWebchatOptions, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { connect } from "react-redux";
import { actionTypes, genericSingleAction } from "../../Redux/actions";
import { Dispatch, AnyAction } from 'redux';
import { diff } from "deep-object-diff";
import { transformWebchatOptions } from "./webChatJsonEditor";
import { CustomizationEntrySelector } from "./customizationEntrySelector";
import { ContainerName } from "../../Redux/reducers/AppReducer";
import { TextField } from "@fluentui/react";

interface StateProps {
    initialized: boolean;
    currentStyleOptions: Partial<WebchatOptions>;
    customizationEntries: CustomizationEntry[];
    webchatOptions: WebchatOptions;

}

interface DispatchProps {
    updateStyleElement: (styleElementName: string, containerName: ContainerName, value: any) => void;
}

interface Props {

}
interface LocalState {
    textValue?: string,
}

type PropsType = StateProps & DispatchProps & Props;

export class WebChatOptionsSearch  extends React.Component<PropsType, LocalState> {
    constructor(props: PropsType) {
        super(props);
        this.state = ({
            textValue: "",
          
        })
    }

   

    getStyleOptionValue = (key: any, containerName: ContainerName = "styleOptions") => {
        const { webchatOptions } = this.props;
        return this.prop(containerName === "root" ? webchatOptions : containerName === "UI.headerOptions" ? webchatOptions["UI"]["headerOptions"]: webchatOptions[containerName], key);// || this.prop(webchatOptions["UI"], key);
    };

    prop<T, K extends keyof T>(obj: T, key: K) {
        if(!obj) {
            return null;
        }
        return obj[key];
    }

    render() {
        const { customizationEntries, updateStyleElement } = this.props;
        const filterValue = this.state.textValue?.toLocaleLowerCase();
        const filteredCustomizationEntries = customizationEntries.filter(c => filterValue && filterValue.length >= 3 
            && (c.displayName.toLocaleLowerCase().indexOf(filterValue.trim()) > -1 || c.id.toLocaleLowerCase().indexOf(filterValue.trim()) > -1));
        return  (<>
        {/* <p>{`Search:`}</p> */}
        <TextField multiline={false} 
        placeholder={"Type a minimum of 3 characters to search..."}
        onChange={(e: any, newValue?: string) => {this.setState({
            textValue: newValue
        })}}/>
        <div style={{width: '100%', height: 2, backgroundColor: 'black', margin: '15px 0'}}></div>
        {
            filteredCustomizationEntries && filteredCustomizationEntries.length ? filteredCustomizationEntries.map((entry: CustomizationEntry) => (
                <CustomizationEntrySelector
                    key={entry.id}
                    entry={entry}
                    value={this.getStyleOptionValue(entry.id, entry.containerName)}
                    onChange={updateStyleElement}
                    getStyleOptionValue={(key, containerName) => this.getStyleOptionValue(key, containerName || entry.containerName)}
    
                />))
                :
                <div>
                    <strong>No items match your search</strong>
                </div>
        }
        </>)
        
        
    }
}

const mapStateToProps = (state: IAppState, ownProps: Props): StateProps => ({
    initialized: state.initialized,
    currentStyleOptions: { ...diff(defaultWebchatOptions(), transformWebchatOptions(state.webchatOptions)), customDruidStyleSet: state.webchatOptions.customDruidStyleSet },
    customizationEntries: state.customizationEntries,
    webchatOptions: state.webchatOptions,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
    updateStyleElement: (styleElementName: string, value: any, containerName?: ContainerName) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_STYLE_ELEMENT, { styleElementName: styleElementName, value: value, containerName: containerName }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebChatOptionsSearch);