import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { initializeIcons } from '@uifabric/icons';
import WebChatEditor from './packages/webchatEditor/components/webChatEditor';


export class App extends React.Component<{botId: string, baseUrl: string, botName: string, documentationUrl: string, onSuccess: () => any,  onClose: () => any, onSave: (json: any) => any}, any> {
  constructor(props: any) {
    super(props);
    initializeIcons();
  }

  render() {
    return (
      <WebChatEditor botId={this.props.botId} baseUrl={this.props.baseUrl} botName={this.props.botName} documentationUrl={this.props.documentationUrl} onClose={this.props.onClose} onSave={this.props.onSave} onSuccess={this.props.onSuccess}/>
    );
  }
}

export default App;
