import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from 'redux';
import { CustomizationEntry, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { genericSingleAction, actionTypes } from "../../Redux/actions";
import { Category } from "../../utilities/types";
import { CustomizationEntrySelector } from "./customizationEntrySelector";
import { CustomizationEntrySubCategory } from "./customizationEntrySubCategory";
import { mergeStyles } from "@fluentui/react";
import { ContainerName } from "../../Redux/reducers/AppReducer";

const entryViewerContainer = mergeStyles(
    {
        display: 'block',
        overflowY: 'auto',
        paddingTop: '25px',
        paddingBottom: '25px',
        '> div:last-of-type': {
            borderBottom: '1px solid #DDE2EA !important',
        },
        '@media (max-height: 765px)': {
            maxHeight: '400px'   
        }
        // maxHeight: '86%',
    }
);

const defaultEntriesContainer = mergeStyles(
    {
        marginBottom: '10px',
    }
);

interface ISubCategoryObject {
    [key: string]: CustomizationEntry[],
}

interface StateProps {
    activeCategory: Category;
    customizationEntries: CustomizationEntry[];
    webchatOptions: WebchatOptions;
}

interface DispatchProps {
    updateStyleElement: (styleElementName: string, containerName: ContainerName, value: any) => void;
}

interface Props {
    updateRootStateVariable: (stateVariableName: string, value: any) => void,

}

type PropsType = StateProps & DispatchProps & Props;


export class ConfigurationEntryViewer extends React.Component<PropsType> {
    constructor(props: PropsType) {
        super(props);
    }

    createSubCategoriesObject = () : ISubCategoryObject => {
        const { customizationEntries, activeCategory } = this.props
        const subCategoryObject: ISubCategoryObject = {};
            
        customizationEntries.forEach((entry: CustomizationEntry) => {
            if (entry.category === activeCategory) {
                if (entry.subCategory) {
                    if (subCategoryObject[entry.subCategory]) {
                        subCategoryObject[entry.subCategory].push(entry);
                    } else {
                        subCategoryObject[entry.subCategory] = [entry];
                    }
                } else {
                    if (subCategoryObject.noSubCategory) {
                        subCategoryObject.noSubCategory.push(entry);
                    } else {
                        subCategoryObject.noSubCategory = [entry];
                    }
                }
            }
        });

        return subCategoryObject;
    }

    getStyleOptionValue = (key: any, containerName: ContainerName = "styleOptions") => {
        const { webchatOptions } = this.props;
        // const containerObjPath = containerName.split('.');
        const containerValue = containerName === "root" ? webchatOptions : containerName === "UI.headerOptions" ? webchatOptions["UI"]["headerOptions"]: webchatOptions[containerName];
        //] containerObjPath.reduce((a, b) => a && (a as any)[b], webchatOptions);
        return this.prop(containerValue, key);// || this.prop(webchatOptions["UI"], key);
    };

    prop<T, K extends keyof T>(obj: T, key: K) {
        if(!obj) {
            return null;
        }
        return obj[key];
    }
    
    mapSubCategoriesObjectToReactElements = (subCategoryObject: ISubCategoryObject) => {
        const { webchatOptions, updateStyleElement } = this.props;
        return (
            <>
                {Object.keys(subCategoryObject).map((key, index) => {
                    if (key === "noSubCategory") {
                        return <div key={key} className={defaultEntriesContainer}>
                                    {subCategoryObject[key].map((entry: CustomizationEntry) => (
                                        <CustomizationEntrySelector
                                            key={entry.id}
                                            entry={entry}
                                            value={this.getStyleOptionValue(entry.id, entry.containerName)}
                                            onChange={updateStyleElement}
                                            getStyleOptionValue={(key, containerName) => this.getStyleOptionValue(key, containerName || entry.containerName)}

                                        />
                                    ))}
                                </div>
                    } else {
                        return <CustomizationEntrySubCategory
                                    key={key}
                                    entries={subCategoryObject[key]}
                                    subCategory={key}
                                    webchatOptions={webchatOptions}
                                    updateStyleElement={updateStyleElement}
                                    getStyleOptionValue={this.getStyleOptionValue}
                                />
                    }
                })}
            </>
        );
    };

    render() {
        const { activeCategory } = this.props;
        const subCategoriesObject = this.createSubCategoriesObject();
        
        return (
            <Fragment>
                <h5>{activeCategory}</h5>
                {/* <p>{`Start building your ${activeCategory}.`}</p> */}
                <div className={entryViewerContainer}>
                    {this.mapSubCategoriesObjectToReactElements(subCategoriesObject)}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state: IAppState, ownProps: Props): StateProps => ({
    activeCategory: state.activeCategory,
    customizationEntries: state.customizationEntries,
    webchatOptions: state.webchatOptions,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
    updateStyleElement: (styleElementName: string, value: any, containerName?: ContainerName) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_STYLE_ELEMENT, { styleElementName: styleElementName, value: value, containerName: containerName }));
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigurationEntryViewer);