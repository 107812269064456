import React, { useMemo } from "react";
import { defaultWebchatOptions, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { connect } from "react-redux";
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';
import { mergeStyles, mergeStyleSets } from '@uifabric/merge-styles';
import { useEffect } from "react";
import { FC } from "react";
import { Dispatch, AnyAction } from 'redux';
import { actionTypes, genericSingleAction } from "../../Redux/actions";



declare const DruidWebchat_v2: {
  [key: string]: any;
  take: (actionType: string) => any;
};
////////////////// Styling //////////////////

let WebChatContainerClassName = mergeStyles(
  {
    height: '70vh',
    margin: '20px 25px'
  }
);

let WebChatPaneClassName = mergeStyles(
  {
    height: '82vh',
    // background: '#D2D6D9',
    padding: '30px 20px'
  }
);

////////////////// WebChat //////////////////

interface StateProps {
  webchatOptions: WebchatOptions;
  getState: () => IAppState
}

interface DispatchProps {
  updateStyleOptions: (value: any) => void,
}

interface Props {
  botId: string; 
  baseUrl: string;
}

type PropsType = StateProps & DispatchProps & Props;



export const WebChat: FC<PropsType> = (props) => {

  useEffect(() => {
    // const {styleOptions: toSetWebChatStyleOptions } = DruidWebchat_v2.visitMergedChatOptions({ UI: {}, styleOptions: props.webChatStyleOptions});
    const defaultOptions = {
      botId: props.botId ||'0ddf3836-9857-4da5-a136-08d90fb74ba3',
      baseUrl:  props.baseUrl ||'https://druidapi.develop.druidplatform.com',
      // _loadConfigurationJSON: loadConfigurationValue,
      UI: {
        // parentElement: document.querySelector('.webchat-editor-container'),
        autoExpandEnabled: true,
        height: '80vh',
        bottom: '5%'
        // headerOptions: {
        //   showReload: true
        // }
      },
    };

    // const mergedOptions = { ...props.webchatOptions, ...defaultOptions }
    const mergedOptions = {  ...defaultOptions }

    DruidWebchat_v2.init(mergedOptions);

    return () => {
      DruidWebchat_v2.destroy();
    }
  }, [props.botId, props.baseUrl]);

  useEffect(() => {
    DruidWebchat_v2.customGeneratorFunction = function* (action: any) {
      
      if (action.type === 'RESET') {
        yield DruidWebchat_v2.take('SET_LOADING');
        // console.info(props.webchatOptions);
        yield DruidWebchat_v2.put({ type: 'SET_MERGEDCHATOPTIONS', payload: props.webchatOptions });
      }

      if (action.type === 'SET_LOADING') {
        // @ts-ignore
        const { payload } = yield DruidWebchat_v2.take('SET_LOADCONFIGURATIONRESULT');
        if(payload) {
          
          const result = defaultWebchatOptions(payload);
          props.updateStyleOptions(result);
        }
      }
    };


    return () => {
      DruidWebchat_v2.customGeneratorFunction = null;
    }
  }, [props.webchatOptions]);
  return (
    <div className={WebChatPaneClassName}>
      <div className={`${WebChatContainerClassName} webchat-editor-container`}>
      </div>
    </div>
  );
}


////////////////// Redux //////////////////

const mapStateToProps = (state: IAppState, ownProps: Props): StateProps => ({
  webchatOptions: state.webchatOptions,
  getState: () => state
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  updateStyleOptions: (value: any) => {
    dispatch(genericSingleAction<any>(actionTypes.UPDATE_STYLE_OPTIONS, value ));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebChat);
