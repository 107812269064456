import React from "react";
import { withRouter } from "react-router-dom";
import { defaultWebchatOptions, IAppState, WebchatOptions } from "../../Redux/reduxState";
import { connect } from "react-redux";
import WebChat from "./webChat";
import { mergeStyles, mergeStyleSets } from '@uifabric/merge-styles';
import { actionTypes, genericSingleAction } from "../../Redux/actions";
import { Dispatch, AnyAction } from 'redux';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { CollapsibleHeader } from "./collapsibleHeader";
import { ColorForm } from "./colorForm";
import { FontForm } from "./fontForm";
import { AvatarForm } from "./avatarForm";
import { MiscForm } from "./miscForm";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import WebChatJsonEditor, { transformWebchatOptions } from "./webChatJsonEditor";
import { Category } from "../../utilities/types";
import { CategoryLink } from "./categoryLink";
import ConfigurationEntryViewer from "./configurationEntryViewer";
import { diff } from 'deep-object-diff';
import WebChatOptionsSearch from "./WebChatOptionsSearch";
import { Icon } from "@fluentui/react";

////////////////// Styling //////////////////
// cosnt leftGridClassName = mergeStyles(
//     {
//         width: '66% !important',
//         height: '86vh',
//         overflowY: 'scroll',
//         marginTop: '20px'
//     }
// );

// const rightGridClassName = mergeStyles(
//     {
//         width: '34% !important',
//         paddingRight: '0px !important',
//         paddingLeft: '0px !important',
//         height: '100%'
//     }
// );
const rootContainer = mergeStyles(
    {
        fontFamily: 'Roboto !important',

    }
);
const headerContainer = mergeStyles(
    {
        display: 'flex',
        alignItems: 'center',
        // borderBottom: '1px solid #000000',
        borderBottom: '1px solid #DDE2EA !important',
        // borderTop: '1px solid #DDE2EA !important',
        paddingTop: 13,
        paddingBottom: 13,
        // height: '80px',
        paddingLeft: '40px',
        paddingRight: '40px'
        // backgroundColor: 'lightPink',
    }
);


const pivotHeader = mergeStyles(
    {
        marginTop: '15px'
    }
);

// border-bottom: 3px;
//     margin-bottom: -1px;
//     border-bottom-color: #70B244;
//     border-bottom-style: solid;
//     font-weight: 500;
//     transition: none !important;
export const DruidColor = '#70B244';
const editorContainer = mergeStyles(
    {
        padding: '33px',
        paddingTop: '5px',
        paddingBottom: '5px',
        '.ms-Pivot': { // tab container
            display: 'flex',
            margin: '0px 0px 32px 0px !important',
            borderBottom: '1px !important',
            borderBottomStyle: 'solid !important',
            borderBottomColor: '#909090 !important',
            flex: 1,
            rowGap: 5,
            height: 60,
            '.ms-Pivot-link': {
                alignItems: 'stretch',
                marginRight: '0.25rem',
                marginBottom: -1,
                padding: '20px 40px !important',
                paddingBottom: 20,
                lineHeight: 18,
                height: '100%',
                '.ms-Button-flexContainer': {

                    '.ms-Pivot-text': {
                        fontSize: 15
                    },
                },

            },
            '.ms-Button--command.ms-Pivot-link.is-selected': {
                'border-bottom': '3px',
                marginBottom: -1,
                borderBottomColor: DruidColor,
                borderBottomStyle: 'solid',
                fontWeight: 500,
                transition: 'none !important',
                color: '#202020 !important',
                // border: 0,
                ':before': {
                    display: 'none'
                },
            }

        },

        // fontSize: '15px !important'
        // border: '1px solid #000000',
        // backgroundColor: 'lightGreen',
    }
);

const configurationEntriesContainer = mergeStyles(
    {
        paddingLeft: '25px !important',
        height: '70h',
        maxHeight: '70vh',
        // backgroundColor: 'purple !important',
        overflowY: 'auto',
        overscrollBehavior: 'none',
        '.ms-Checkbox.is-checked': {

            '.ms-Checkbox-checkbox': {
                background: DruidColor,
                borderColor: `${DruidColor} !important`,
            }
        },
        '.ms-Checkbox-checkbox': {
            border: '1px solid #B2B2B2',
        },
        '.ms-TextField-fieldGroup': {
            borderRadius: 4,
            border: '1px solid #B2B2B2',
            ':hover, ::after': {
                border: `1px solid ${DruidColor}`,
                borderRadius: 4
            },
            '.ms-TextField-prefix': {
                borderRadius: 4
            }
            // border: '1px solid red',
        },
        '.ms-Dropdown': {
            '.ms-Dropdown-title': {
                borderRadius: 4,
                border: '1px solid #B2B2B2',

            },
            '::after': {
                border: `2px solid ${DruidColor}`,
                borderRadius: 4
            },
        },
        '.ms-Button--primary': {
            background: DruidColor,
            borderColor: `${DruidColor} !important`,
            ':hover, :active': {
                background: '#5C9C2B',
                borderColor: `#5C9C2B !important`

            }
        }

    }
);

const configurationCategoriesContainer = mergeStyles(
    {
        rowGap: 10
    }
);

const stackTokens = { childrenGap: 10 };


////////////////// WebChatEditor //////////////////

interface StateProps {
    activeCategory: Category;
    initialized: boolean;
    currentStyleOptions: Partial<WebchatOptions>;
}

interface DispatchProps {
    updateStyleElement: (styleElementName: string, value: any) => void,
    updateRootStateVariable: (stateVariableName: string, value: any) => void,
}

interface Props {
    botId: string;
    baseUrl: string;
    botName: string;
    documentationUrl: string;
    onClose: () => any;
    onSave: (json: any) => any;
    onSuccess: () => any;
}

type PropsType = StateProps & DispatchProps & Props;

export class WebChatEditor extends React.Component<PropsType> {
    constructor(props: PropsType) {
        super(props);
    }


    private RenderCategories = () => {
        let resultingUIComponents = [];

        const { activeCategory } = this.props;

        for (let category in Category) {
            resultingUIComponents.push(
                <CategoryLink
                    key={category}
                    name={category}
                    activeCategory={activeCategory}
                    updateRootStateVariable={this.props.updateRootStateVariable}
                />
                
            );
        }

        return resultingUIComponents;
    }

    render() {
        return (
            <div className={`ms-Grid ${rootContainer}`} dir="ltr">
                <div className={`ms-Grid-row ${headerContainer}`}>
                    <div className="ms-Grid-col flex-grow-1 d-flex">
                        <h5 className="font-weight-bold mb-2 mr-5 mt-2 text-dark">{this.props.botName}</h5>
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-1 bg-gray-200" style={{ width: 1, height: 22 }}></div>
                        <div className="d-flex align-items-center"><span className="text-muted font-weight-bold ml-4 mr-1 " style={{ fontSize: 12, fontWeight: 400 }}>Webchat</span></div>

                        <div className="d-flex align-items-center"><span className="text-muted font-weight-bold mr-1 " style={{ fontSize: 12, fontWeight: 400 }}> &#183; Customize webchat</span></div>
                        <a title="Documentation" className="help-actions-icon page-help-icon" style={{cursor:  "pointer", marginLeft: 20, alignSelf: 'center'}} href={this.props.documentationUrl} target="_blank" rel="noopener noreferrer" onClick={() => {

                        }}>
                            <svg  xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512">
                                <path  fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
                            </svg>
                            </a>
                    </div>
                    <div className="right">
                        {/* <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-outline-secondary">Left</button>
                            <button type="button" className="btn btn-outline-secondary">Middle</button>
                            <button type="button" className="btn btn-outline-secondary">Right</button>
                        </div> */}
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary" onClick={() => this.props.onSave(this.props.currentStyleOptions)}> Save  </button>
                            <button type="button" className="btn btn-secondary" onClick={this.props.onClose}> Cancel </button></div>
                    </div>
                </div>

                <div className={`ms-Grid-row webchat-editor-container ${editorContainer}`}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Pivot aria-label="Large Link Size Pivot Example">
                            <PivotItem className={pivotHeader} headerText="GUI">
                                {
                                    this.props.initialized && <div className="ms-lg12">
                                        <div className={`ms-Grid-col ms-sm3 ms-md3 ms-lg3 ${configurationEntriesContainer}`}>
                                            <Stack tokens={stackTokens} className={`${configurationCategoriesContainer}`}>
                                                {this.RenderCategories()}
                                            </Stack>
                                        </div>
                                        <div className={`ms-Grid-col ms-sm9 ms-md9 ms-lg9 ${configurationEntriesContainer}`}>
                                            <ConfigurationEntryViewer updateRootStateVariable={this.props.updateRootStateVariable} />
                                        </ div>
                                    </div>
                                }
                            </PivotItem>
                            <PivotItem headerText="Json Viewer">
                                <WebChatJsonEditor onSuccess={this.props.onSuccess} />
                            </PivotItem>
                            {/* <PivotItem headerText="Search Options" className={pivotHeader}>
                                <div className={`ms-Grid-col ms-sm8 ms-md8 ms-lg8 ${configurationEntriesContainer}`}>
                                    <WebChatOptionsSearch />
                                </div>
                            </PivotItem> */}
                        </Pivot>
                    </div>
                    {/* <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"> */}
                    <WebChat {...this.props} />
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

////////////////// Redux //////////////////

const mapStateToProps = (state: IAppState, ownProps: Props): StateProps => ({
    activeCategory: state.activeCategory,
    initialized: state.initialized,
    currentStyleOptions: { ...diff(defaultWebchatOptions(), transformWebchatOptions(state.webchatOptions)), customDruidStyleSet: state.webchatOptions.customDruidStyleSet },
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
    updateStyleElement: (styleElementName: string, value: any) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_STYLE_ELEMENT, { styleElementName: styleElementName, value: value }));
    },
    updateRootStateVariable: (stateVariableName: string, value: any) => {
        dispatch(genericSingleAction<any>(actionTypes.UPDATE_ROOT_WEBCHAT_STATE_VARIABlE, { propertyName: stateVariableName, value: value }));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebChatEditor);