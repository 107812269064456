export enum Category {
    // The order of this enum will effect
    // the order they render on the webapp
    Search = "Search",
    General = "General",
    Layout = 'Layout',
    ColorAndPaddings = 'Color & Paddings',
    Fonts = 'Fonts',
    Buttons = 'Buttons',
    ChatBubble = 'Chat Bubble',
    Avatar = 'Avatar',
    ConnectivityUI = 'Connectivity UI',
    SendBox = 'Send Box',
    SuggestedActions = 'Suggested Actions',
    TimestampAndStatus = 'Timestamp and Status',
    ToastUI = 'Toast UI',
    TranscriptOverlayButtons = 'Transcript Overlay Buttons',
    Miscellaneous = 'Miscellaneous',
    CustomCSS = 'Custom Styling',
    Handlers = "Custom Handlers",
    Localization = "Localization"

}

export enum SubCategory {
    AvatarChatBubble = 'Bot Chat Bubble',
    AvatarSettings = 'Avatar Settings',
    BotAvatar = 'Bot Avatar',
    Border = 'Border',
    BorderDisabled = 'Border (Disabled)',
    ButtonColors = 'Button Colors',
    ButtonDefault = 'Button: Default',
    ButtonOnFocus = 'Button: On Focus',
    ButtonOnHover = 'Button: On Hover',
    CarouselFlipper = 'Carousel Flipper',
    ChatBox = 'Chat Box',
    Colors = 'Colors',
    ConnectivityStyling = 'Connectivity Styling',
    Container = 'Container',
    Cookies = 'Cookies',
    Error = 'Error',
    Common = 'Common',
    Header ='Header',
    Icons = 'Icons',
    Info = 'Info',
    Layout = 'Layout',
    NotificationTimeout = 'Notification Timeout',
    ScrollToEndButton = 'Button: Scroll to end',
    SlowConnectionTimeOut = 'Slow Connection Timeout',
    SpinnerAnimation = 'Spinner Animation',
    Status= 'Status',
    Success = 'Success',
    TimeStamp = "Timestamp",
    ToasterLayout = 'Toaster Layout',
    ToasterStyling = 'Toaster Styling',
    TypingAnimation = 'Typing Animation',
    UploadThumbnail = 'Upload Thumbnail',
    UserAvatar = 'User Avatar',
    UserChatBubble = 'User Chat Bubble',
    Warn = 'Warn',
}

export enum UISelectorType {
    booleanSelector = 'booleanSelector',
    colorSelector = 'colorSelector',
    defaultSelector = 'defaultSelector',
    dropDownSelector = 'dropDownSelector',
    integerSelector = 'integerSelector',
    percentageSelector = 'percentageSelector',
    rgbaSelector = 'rgbaSelector',
    reactSelector = 'reactSelector',
    localizationSelector = 'localizationSelector',
    searchSelector = 'searchSelector'
}
