import * as React from 'react';
import { GroupedList, IGroup, IGroupHeaderProps, IGroupFooterProps } from 'office-ui-fabric-react/lib/GroupedList';
import { Link } from 'office-ui-fabric-react/lib/Link';
// import { createListItems, createGroups, IExampleItem } from '@uifabric/example-data';
import { mergeStyleSets, IRawStyle } from 'office-ui-fabric-react/lib/Styling';
import { CustomizationEntry, WebchatOptions } from '../../Redux/reduxState';
import { CustomizationEntrySelector } from './customizationEntrySelector';
import { mergeStyles } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { ContainerName } from '../../Redux/reducers/AppReducer';

const subCategoryHeaderContainer = mergeStyles(
    {
        display: "flex",
        alignItems: "center",
        // margin: '10px 0',
        padding: '13px 16px',
        height: '58px !important',
        fontSize: '15px !important',
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
        border: '1px solid #DDE2EA !important',
        'border-left': 'unset !important',
        'border-right': 'unset !important',
        'background': 'white !important',
        color: '#202020 !important',
        'border-bottom': 'unset !important'
    }
);

const subCategoryContentContainer = mergeStyles(
    {
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
    }
)

const subCategoryHeaderIcon = mergeStyles(
    {
        fontSize: '0.75rem',
        display: 'flex',
        aligntItems: 'center',
        cursor: 'pointer',
        marginLeft: 'auto'
    }
);

const subCategoryHeader = mergeStyles(
    {
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        color: '#212529',
        fontWeight: 'bold',
        cursor: 'pointer',
    }
);

interface CustomizationEntrySubCategoryProps {
    entries: CustomizationEntry[];
    subCategory: string;
    webchatOptions: WebchatOptions;
    updateStyleElement: (styleElementName: string, containerName: ContainerName, value: any) => void;
    getStyleOptionValue: (key: any, containerName?: ContainerName) => any;
}

export const CustomizationEntrySubCategory = (props: CustomizationEntrySubCategoryProps) => {
    const { entries, subCategory, webchatOptions, updateStyleElement, getStyleOptionValue
} = props;
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(true); // collapses subCategory headers
    }, [subCategory]);        // when new Category is selected, subcategory changes
    

    // const getStyleOptionValue = (key: any) => {
    //     return prop(webchatOptions, key);
    // };

    // const prop = <T, K extends keyof T>(obj: T, key: K) => {
    //     return obj[key];
    // }

    
    
    return (
        <>
            <div
                onClick={()=> {setIsCollapsed(!isCollapsed)}}
                className={`${subCategoryHeaderContainer}`}
            >
                {/*  */}
                <span className={subCategoryHeader}>
                    {subCategory}
                </span>
                <div className={subCategoryHeaderIcon}>
                    <Icon iconName={isCollapsed ? "ChevronDownSmall" : "ChevronUpSmall"}/>
                </div>
            </div>
            {
                isCollapsed
                    ?   <div />
                    :   <div  className={`${subCategoryContentContainer}`}>
                            {entries.map((entry: CustomizationEntry) => (
                                    <CustomizationEntrySelector
                                        entry={entry}
                                        key={entry.id}
                                        value={getStyleOptionValue(entry.id, entry.containerName)}
                                        onChange={updateStyleElement}
                                        getStyleOptionValue={(key, containerName) => getStyleOptionValue(key, containerName || entry.containerName)}
                                    />
                                )
                            )}
                        </div>
            }
        </>
    );
};
