import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from 'redux';
import { IAppState } from "../../Redux/reduxState";
import { genericSingleAction, actionTypes } from "../../Redux/actions";
import { Category } from "../../utilities/types";
import CSS from 'csstype';

import { Icon, mergeStyles } from "@fluentui/react";

const categoryHeaderContainer = mergeStyles(
    {   
        cursor: 'pointer',
        display: 'flex',
        aligntItems: 'center',
    }
);

interface CategoryLinkProps {
    name: string;
    activeCategory: Category;
    updateRootStateVariable: (stateVariableName: string, value: any) => void
}

export const CategoryLink = (props: CategoryLinkProps) => {
    const {updateRootStateVariable, name, activeCategory } = props;

    const getCategory = (stringName: string) : Category => {
        // finds corresponding category entry (as tuple) from Category enum 
        const categoryEntry: [string, Category] | undefined = Object.entries(Category).find(x => x[0] === stringName);

        const category: Category = categoryEntry![1];
        return category;
    };

    const displayName: Category = getCategory(name);
    const conditionalCategoryStyle: CSS.Properties = displayName === activeCategory
        ? {
            fontWeight: "bolder",
            color: '#70B244'
        } // if category is active
        : {

        };
    
    const categoryStyle: CSS.Properties = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        ...conditionalCategoryStyle,
    };

    const conditionalIconStyle: CSS.Properties = displayName === activeCategory
        ? {}
        : {opacity: '0'};

    
    const iconStyle: CSS.Properties = {
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'center',
        ...conditionalIconStyle,
        cursor: 'pointer'
    };
    return (
        <div
            onClick={() => {updateRootStateVariable('activeCategory', displayName)}}
            className={categoryHeaderContainer}
        >
            {/* <Icon style={iconStyle} iconName="ChevronRightSmall" /> */}
            {/* <Icon style={iconStyle} iconName="ChevronRightSmall" /> */}
                
            <span style={categoryStyle}>{displayName}</span>
            { name === 'Search' && <svg style={{marginLeft: 10, alignSelf: 'center'}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>}

        </div>
    );
};
